import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { HorizontalSeparator } from '../../../app/components/separator';
import withTranslate from '../../../common/components/with-translate/with-translate';
import classNames from 'classnames';
import ViewsIcon from './icons/views-icon';
import CommentsIcon from './icons/comments-icon';
import ProtectedButton from '../../../app/containers/protected-button';
import LikeButton from '../../../app/components/like-button';
import withCardBorderColor from '../../hoc/with-card-border-color';
import styles from './post-item-footer.scss';
import { LIKE_POST } from '../../../app/constants/interactions';
import CounterNumber from '../../../app/components/counter-number';
import { connect } from '../../../common/components/runtime-context';
import { getEntityLikeCount } from '../../../app/selectors/counters-selectors';

const Separator = withCardBorderColor(HorizontalSeparator);

export const PosItemFooter = ({
  post,
  handleLikeClick,
  titleFontClassName,
  titleFontColorClassName,
  forPublicUser,
  isViewsCountEnabled,
  likeCount,
  t,
}) => {
  const onLikeClick = e => {
    e.preventDefault();
    handleLikeClick(`${post._id}`);
  };

  return (
    <div className={classNames(styles.footerWrapper, titleFontClassName, titleFontColorClassName)}>
      <Separator className={styles.separator} />
      <div className={styles.stats}>
        <div className={styles.statsLeft}>
          {isViewsCountEnabled && (
            <div className={styles.statsViewCount}>
              <ViewsIcon className="icon-fill" />
              <span
                className={styles.statsNumber}
                data-hook="post-list-item__view-count"
                aria-label={t('post-list-item.view-count', { count: post.viewCount })}
              >
                <CounterNumber entityId={post._id} viewCount />
              </span>
            </div>
          )}
          <div className={styles.statsCommentCount}>
            <CommentsIcon className="icon-fill" />
            <span
              className={styles.statsNumber}
              data-hook="post-list-item__total-comments"
              aria-label={t('post-list-item.total-comments', { count: post.totalComments })}
            >
              <CounterNumber initialValue={post.totalComments} entityId={post._id} totalComments />
            </span>
          </div>
        </div>
        <div className={styles.likeContainer}>
          <span
            className={classNames(styles.likeCount)}
            data-hook="post-list-item__like-count"
            aria-hidden
          >
            <CounterNumber initialValue={likeCount} entityId={post._id} likeCount />
          </span>
          <ProtectedButton
            className={classNames(styles.likeButton, titleFontClassName, titleFontColorClassName)}
            onClick={forPublicUser(onLikeClick, { preventDefault: true })}
            data-hook="post-list-item__like-button"
            aria-live="assertive"
            actionDetails={{ action: LIKE_POST, args: [post._id] }}
          >
            <LikeButton
              isLiked={post.isLiked}
              likeCount={likeCount}
              isDisabled={post.isLikeInProgress}
            />
          </ProtectedButton>
        </div>
      </div>
    </div>
  );
};

PosItemFooter.propTypes = {
  post: PropTypes.object.isRequired,
  handleLikeClick: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  titleFontColorClassName: PropTypes.string.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps) => ({
  likeCount: getEntityLikeCount(state, ownProps.post),
});

// prettier-ignore
export default flowRight(connect(mapRuntimeToProps), withTranslate)(PosItemFooter);
